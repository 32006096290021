import React from "react"
import { graphql } from "gatsby"
import Container from "../../components/common/Container"
import GridTwo from "../../components/common/GridTwo"
import GridThree from "../../components/common/GridThree"
import CardPrint from "../../components/common/CardPrint"
import Layout from "../../components/layouts/Default"
import { H2 } from "../../components/typography/Headings"
import P from "../../components/typography/P"
import PageHeader from "../../components/common/PageHeader"
import Breadcrumb from "../../components/common/Breadcrumb"

export default function Page({ data, location }) {

  return (
    <Layout location={location} title="Spezial" desc="">
      <PageHeader title="Spezial" image={data.header} />
      <Breadcrumb pages={[{ name: "Verlag", to: "/verlag/" },{ name: "Spezial", to: "/verlag/spezial/" }]} />

      <Container className="space-y-8 md:space-y-16">
      <div>
          <div className="mb-8 md:mb-16">
            <H2 className="mb-5 font-display">NATURBLICK | Spezial 2020</H2>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Wollen Sie Wildtiere in ihrem natuürlichen Lebensraum
                  fotografieren, ohne sie zu beunruhigen oder in ihrem
                  ursprünglichen Verhalten zu stören?
                </P>
                <P>
                  Wollen Sie dabei keine Standardbilder machen, von denen es
                  schon zu viele gibt, sondern herausragende Tierbilder, die den
                  Betrachter in ihren Bann ziehen?
                </P>
                <P>Dann müssen Sie dieses NATURBLICK-Spezial lesen!</P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.spezial.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/spezial/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>

      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1600)
      }
    }
    spezial: allPublikationen(
        sort: { fields: date, order: DESC }
        filter: { art: { eq: "Spezial" } }
      ) {
        nodes {
          remoteCoverImage {
            childImageSharp {
              gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1024)
            }
          }
          title
          title_slug
        }
      }
  }
`
